.loadingSpinner {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: rgb(25, 25, 25, 0.3);
}
.hideLoading {
  visibility: hidden;
}
