body {
  margin: 0;
  /* T R B L */
  padding: 0 0 0 0;
  font-family: sans-serif;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  display: flex !important;
  min-width: -webkit-fill-available;
  justify-content: center;
}
.dialog {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 20px;
  align-items: center !important;
}
.modal-dialog {
  max-width: 620px !important;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
}
.modal-header {
  width: 100%;
}
.close {
  position: absolute;
  right: 20px;
}
.modal-content {
  overflow: hidden !important;
  position: relative !important;
  padding: 10px;
}
