* {
  margin: 0;
}
.row {
  margin: 0 !important;
  justify-content: center;
}
.HomePage {
  padding-right: 1rem;
  padding-left: 1rem;

  max-width: 1440px;
  min-width: fit-content;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 580px) {
  .HomePage {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 1rem;
  }
}
@media only screen and (min-width: 1000px) {
  .HomePage {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
@media only screen and (min-width: 1200px) {
  .HomePage {
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

@media only screen and (max-width: 468px) {
  .containerOfBody {
    padding-top: 4.5rem;
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #0a0a0a;
    background-color: 0;
    flex-direction: row;
    overflow: auto;
    /* background-color: rgb(189, 203, 228); */
  }
  .HomePage {
    padding-top: 1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 468px) {
  .containerOfBody {
    margin: 0;
    padding-top: 3rem;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #0a0a0a;
    background-color: 0;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: auto;
    /* background-color: rgb(189, 203, 228); */
  }
}
.searchPatients {
  min-width: 485px;
  overflow-x: auto;
}
.table {
  white-space: pre-wrap;
  margin: auto;
  width: fit-content !important;
  overflow: auto;
  display: block;
}
.container-patients {
  min-width: 468px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  align-items: center;
}
.containerOfDashboard {
  display: flex;
  direction: ltr;
  flex-direction: row;
  justify-content: space-between;
  justify-items: space-between;
  width: fit-content;
  background-color: rgb(189, 203, 228);
}
.containerOfDashboardRightPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: space-between;
  padding: 4em 2em 2em 0;
  background-color: rgb(189, 203, 228);
}
.ex5 .parent {
  display: grid;
  grid-template: auto 1fr auto / auto 1fr auto;
}

.ex5 header {
  padding: 2rem;
  grid-column: 1 / 4;
}

.ex5 .left-side {
  grid-column: 1 / 2;
}

.ex5 main {
  grid-column: 2 / 3;
}

.ex5 .right-side {
  grid-column: 3 / 4;
}

.ex5 footer {
  grid-column: 1 / 4;
}

.flexGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.containerOfPage {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: 0;
}
.container {
  padding-top: 2rem;
}
.box {
  height: 10em;
  width: fit-content;
  display: flex;
}
.statusBadge {
  width: min-content;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  height: auto;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}
.autoSizedBox {
  height: auto;
  width: auto;
  position: static;
  right: 0;
  left: 0;
}
:root {
  --part1Div: 20%;
  --part2Div: 40%;
  --part3Div: 40%;
  --total: 100%;
}

.pink {
  background-color: lightpink;
  height: calc(var(--total) - var(--part1Div));
  width: 100vw;
}
.red {
  background-color: lightcoral;
  width: 100vw;
  height: var(--part1Div);
  bottom: 0;
}

.blue {
  background-color: rgb(189, 203, 228);
  /* height: calc(var(--total) - var(--part2Div)); */
  left: 10em;
  right: 10em;
  padding: 1em;
  height: fit-content;
  display: block;
}
.bluish {
  background-color: #6e93c4;
  right: 10em;
  left: 10em;
  height: var(--part2Div);
  bottom: 0;
  width: 470px;
  min-height: 600px;
  overflow-y: scroll;
  border-radius: 5px;
  margin-bottom: 3px;
}

.green {
  background-color: #8696b4;
  right: 0em;
  height: calc(var(--total) - var(--part3Div));
  width: 470px;
  overflow-y: scroll;
  border-radius: 5px;
  margin-bottom: 3px;
}

.greenish {
  background-color: #618f94;
  right: 0em;
  height: calc(var(--total) - var(--part2Div));
  width: 470px;
  overflow-y: scroll;
  border-radius: 5px;
}
.pageNav {
  position: absolute;
}

.searchBar {
  padding: 5px;
  font-size: larger;
  outline-width: 5px;
  outline-color: mediumslateblue;
  border-width: 2px;
  outline-style: solid;
  outline: mediumpurple;
}
.searchBar:focus {
  color: #495057;
  background-color: #fff;
  border-color: ghostwhite;
  outline: 0;
  box-shadow: 0 0 10px 0.2rem rgba(147, 112, 216, 0.882);
}

.editRow {
  padding: 3px;
  font-size: larger;
  outline-width: 5px;
  outline-color: tomato;
  border-width: 2px;
  outline-style: solid;
  outline: mediumpurple;
  overflow-x: hidden;
}
.editRow:focus {
  overflow: auto;
  overflow-x: hidden;
  color: #495057;
  background-color: #fff;
  border-color: ghostwhite;
  outline: 0;
  box-shadow: 0 0 10px 0.2rem rgba(216, 112, 143, 0.882);
  box-sizing: content-box;
  width: auto;
}
.qtyAdd:focus {
  color: #495057;
  background-color: #fff;
  border-color: ghostwhite;
  outline: 0;
  box-shadow: 0 0 10px 0.2rem rgba(147, 112, 216, 0.882);
}

.icons-list {
  list-style-type: none;
  flex-direction: row;
  display: flex;
  margin: 0;
}

.sidebar {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #2c568d;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  /* white-space: nowrap; */
  flex-wrap: nowrap;
}

.Emergency {
  background-color: #dc3545 !important;
  border-radius: 5px !important;
  width: 140px;
  padding: 0 5px 0 5px !important;
}
.Regular {
  background-color: #36454f !important;
  color: rgb(255, 255, 255);
  border-radius: 5px !important;
  width: 140px;
  padding: 0 5px 0 5px !important;
}
.sidebar:hover {
  height: 100%;
  width: 40vw;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #5b79ce;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  white-space: nowrap;
  flex-wrap: wrap;
}
.BTClass {
  padding: 5px;
}
.tr-odd {
  background-color: #2c568d;
}
.tr-even {
  background-color: #12417e;
}
.tableHeader {
  color: aquamarine;
}
.paginationBlock {
  position: fixed;
  bottom: 5px;
  left: 5px;
  z-index: 1030;
  margin-block-end: auto;
}
.upcomingPatientsPaginationBlock {
  top: calc(var(--total) - var(--part3Div));
  left: 5px;
  margin-left: 12px;
  z-index: 1030;
  margin-block-end: auto;
}
.cartIcons {
  cursor: pointer;
  margin: 5px 20px 5px 5px;
  width: 40px;
  height: 40px;
}
.printIcon {
  cursor: pointer;
  margin: 5px 5px 5px 5px;
  width: 40px;
  height: 40px;
}
.unsupported {
  fill: #f70000;
}
.SlidingArrows {
  position: fixed;
  bottom: 10px;
  left: 30px;
}
.form-control:read-only {
  background-color: #fff !important;
  text-align: end;
}
.datePicker {
  background-color: whitesmoke;
}

.filterDropDown {
  justify-items: center;
  display: flex;
  justify-content: center;
  min-width: max-content;
  text-align: center;
}
